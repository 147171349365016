import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  HiOutlineSortDescending,
  HiOutlineSortAscending,
  HiOutlineArrowSmDown 
} from "react-icons/hi";


import {
  Button,
  Dropdown,
  Form,
  Modal,
  Spinner,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import 'react-modern-drawer/dist/index.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import api from '../../api';
import deleteImg from '../../assets/images/delete.png';
import settings from '../../assets/images/settings.png';

// import logo from '../../assets/images/logo.jpg';
import moment from 'moment';
import { io } from 'socket.io-client';
import Sidebar from '../../components/sidebar';
import { AuthContext } from '../../context/auth';
import { ListContext } from '../../context/list';
import { PlansContext } from '../../context/plans/plans';
import AddColumnModal from '../../modals/AddColumnModal/AddColumnModal';
import AddDataRowModal from '../../modals/AddRowModal/AddRowModal';
import EditColumnTitleModal from '../../modals/EditColumnTitleModal/index.js';
import EditModal from '../../modals/EditModal/EditModal';
import GoogleSheetModal from '../../modals/GoogleSheetModal/GoogleSheetModal';
import PlanLimitModal from '../../modals/PlanLimitModal';
import UploadModal from '../../modals/UploadFileModal/UploadModal.js';
import UploadTextModal from '../../modals/UploadTextModal/UploadTextModal';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import './Home.css';
// import Select from 'react-dropdown-select';
import Select from 'react-select';
// import IntegrationSelect from '../../components/integrations/IntegrationSelect.js';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { HiArrowDown } from 'react-icons/hi2';
import { ThreeDots } from 'react-loader-spinner';
import { Header } from '../../components/header/index.js';
import NotificationMsg from '../../components/transformation/NotificationMsg.js';
import Loader from '../../components/shared/loader/Loader.js';
import Pagination from '../../components/pagination/Pagination.js';
const IntegrationLogsTable = lazy(() =>
  import('../../components/integrations/IntegrationLogsTable'),
);
const TransformationHistory = lazy(() =>
  import('../../components/transformation/TransformationHistory'),
);

const IntegrationSelect = lazy(() =>
  import('../../components/integrations/IntegrationSelect'),
);

const Home = () => {
  const tableContainerRef = useRef(null);
  const isFirstRender = useRef(true);
  const scrollTable = (scrollAmount) => {
    const table = tableContainerRef.current;
    if (table) {
      // You can adjust the scroll amount as needed
      const currentScrollLeft = table.scrollLeft;
      // console.log(currentScrollLeft + 'before');
      table.scrollTo(scrollAmount, 0);
      // console.log(table.scrollLeft + 'after');
    }
  };
  const [dropdownPosition, setDropdownPosition] = useState({
    x: null,
    y: null,
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      const tmpPosition = JSON.parse(JSON.stringify(dropdownPosition));
      if (
        tmpPosition.left + dropdownRef.current.clientWidth >=
        window.innerWidth
      ) {
        tmpPosition.left =
          tmpPosition.left - dropdownRef.current.clientWidth - 10;
        setDropdownPosition(tmpPosition);
      }
    }
  }, [dropdownPosition, dropdownRef]);

  const [showSampleData, setShowSampleData] = useState(false);
  const [run, setRun] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  // const onJoyrideCallback = (data) => {
  //   if (data.type === 'step:after' && data.index === 4) {
  //     scrollTable(1588);
  //     // console.log("doneee")
  //   } else if (data.type === 'step:after' && data.index === 6) {
  //     // console.log("Callback triggered for step:", data.index);
  //     // if (data.index === 7) {
  //     scrollTable(0);
  //     // setUploadShow(true);

  //     // }
  //   }
  // };

  // const s1 = [
  //   {
  //     index: 0,
  //     type: 'step:before',
  //     target: '#steponejoy2',
  //     // isFixed :false,

  //     content: <p>Selected Conversion name</p>,
  //   },
  //   {
  //     index: 1,
  //     target: '.step-2',
  //     // type: 'step:before',
  //     content: (
  //       <p>Daily remaining operations left according to your subscription</p>
  //     ),
  //   },
  //   {
  //     index: 2,
  //     target: '#joyonestep3',
  //     // type: 'step:before',
  //     content: (
  //       <p>
  //         Step 1 is aimed to feed the AI with the sample of data that you want
  //         to collect. It should be just several rows of data from 2 to 5 rows,
  //         not less, not more. You can upload this sample from a CSV file by
  //         hitting this button
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 3,
  //     target: '#joyonestep4',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         Step 1 is aimed to feed the AI with the sample of data that you want
  //         to collect. It should be just several rows of data from 2 to 5 rows,
  //         not less, not more. You can upload this sample from a CSV file by
  //         hitting this button
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 4,
  //     target: '#joyonestep5',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         You can select from predefined templates for HubSpot, LinkedIn,
  //         Amazon, ProductHunt, and other sources and destinations of data.
  //         Templates cover such areas as leads and investors for the CRM,
  //         information about consumer and IT products, and other things.
  //       </p>
  //     ),
  //   },
  // ];

  // const s2 = [
  //   {
  //     index: 0,
  //     type: 'step:before',
  //     target: '#steponejoy2',
  //     // isFixed :false,

  //     content: <p>Selected Conversion name</p>,
  //   },
  //   {
  //     index: 1,
  //     target: '.step-2',
  //     type: 'step:before',
  //     content: (
  //       <p>Daily remaining operations left according to your subscription</p>
  //     ),
  //   },
  //   {
  //     index: 3,
  //     type: 'step:before',
  //     target: '.downloading_img',
  //     content: (
  //       <p>
  //         By selecting this option you can reload the sample data. Be careful,
  //         because it will also delete all previously collected data from the
  //         table below
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 4,
  //     type: 'step:before',
  //     target: '.upload_file_convert_btn',
  //     content: (
  //       <p>
  //         This is your primary tool. Tap to upload files, text or URLs to be
  //         converted to the format you uploaded in a Sample. There are limits to
  //         the content size you can upload depending on your subscription.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 5,
  //     type: 'step:before',
  //     target: '#datastep',
  //     content: (
  //       <p>
  //         You can connect Google Sheet to the table below. Only the rows
  //         appended after the connection will be added to the Google Sheet. You
  //         need the popups to be allowed to see the Google Sheets access approval
  //         screen. Note that your organization can restrict connecting with the
  //         Google Sheets for security reasons.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 6,
  //     type: 'step:before',
  //     target: '.exportBtn',
  //     content: (
  //       <p>
  //         You can always export data from the table to the Excel file (xlsx) and
  //         save it on the local machine.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 7,
  //     type: 'step:after',
  //     target: '.tableData',
  //     placement: 'top-end',
  //     offset: -80,
  //     content: (
  //       <p>
  //         You can delete any row - both sample and result. Use it carefully, you
  //         can't restore it
  //       </p>
  //     ),
  //     // action : setUploadShow(true)
  //   },
  // ];
  // // const [joyride1, setJoyride1] = useState(true);
  // const [joyride1, setJoyride1] = useState(false);

  const fieldSelectRef = useRef();

  const [connections, setConnections] = useState(null);
  const [isConnectionsLoading, setIsConnectionsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState(-1);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [fields, setFields] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);

  const [resource1, setResource1] = useState(null);
  const [resource2, setResource2] = useState(null);
  const [resource3, setResource3] = useState(null);
  const [resource4, setResource4] = useState(null);
const [sortBy ,setSortBy]=useState('')
  const [selectedFieldsOptions, setSelectedFieldsOptions] = useState({});
  const [mappings, setMappings] = useState({});
 const [currentPage ,setCurrentPage]=useState(0)
  const [integrationMapping, setIntegrationMapping] = useState(null);
  const [isMappingSaving, setIsMappingSaving] = useState(false);
  const [isMappingDeleting, setIsMappingDeleting] = useState(false);
  const [selectedConversion, setSelectedConversion] = useState('Conversion');
  const[firstPage ,setFirstPage]=useState(0)
  const [lastPage ,setLastPage]=useState(0)

  const handleFieldsLoading = (loading) => {
    setIsFieldsLoading(loading);
  };

  const fetchIntegrationMapping = useCallback(
    async (conversion) => {
      if (conversion && conversion !== 'null') {
        try {
          const response = await api.get(`/integrations/mapping/${conversion}`);
          setIntegrationMapping(response.data);
        } catch (error) {
          console.error('Error fetching IntegrationMapping:', error);
        }
      }
    },
    [setIntegrationMapping],
  );

  const handleSelectMappingChange = useCallback(
    (selectedOption, header) => {
      setMappings((prevMappings) => {
        if (!selectedOption) {
          const newMappings = { ...prevMappings };
          delete newMappings[header];
          return newMappings;
        }
        return {
          ...prevMappings,
          [header]: selectedOption,
        };
      });

      setSelectedFieldsOptions((prevSelectedOptions) => {
        if (!selectedOption) {
          const newSelectedOptions = { ...prevSelectedOptions };
          delete newSelectedOptions[header];
          return newSelectedOptions;
        }
        return {
          ...prevSelectedOptions,
          [header]: selectedOption ? selectedOption.value : null,
        };
      });
    },
    [setMappings, setSelectedFieldsOptions],
  );

  const isOptionDisabled = (option, currentHeader) => {
    if (!selectedFieldsOptions) {
      return false;
    }
    const isSelectedInSession = Object.entries(selectedFieldsOptions).some(
      ([header, value]) => {
        //return header !== currentHeader && value === option.value;
        return (
          header !== currentHeader && String(value) === String(option.value)
        );
      },
    );

    const isSelectedInDB =
      integrationMapping &&
      Object.values(integrationMapping.mapping).some((mapping) => {
        // console.log('mapping.value', typeof mapping.value); //string
        // console.log('option.value', typeof option.value); //number
        //return mapping.value === option.value && mapping.header !== currentHeader;
        return (
          String(mapping.value) === String(option.value) &&
          mapping.header !== currentHeader
        );
      });

    return isSelectedInSession || isSelectedInDB;
  };

  const setResource1FromSelect = useCallback(
    (resource) => {
      setResource1(resource);
    },
    [setResource1],
  );

  const setResource2FromSelect = useCallback(
    (resource) => {
      setResource2(resource);
    },
    [setResource2],
  );

  const setResource3FromSelect = useCallback(
    (resource) => {
      setResource3(resource);
    },
    [setResource3],
  );

  const setResource4FromSelect = useCallback(
    (resource) => {
      setResource4(resource);
    },
    [setResource4],
  );

  const handleMappingSave = useCallback(async () => {
    // console.log('integrationMapping',integrationMapping);
    // console.log('selectedConnection',selectedConnection);
    // console.log('conId',conId);
    // console.log('resource1',resource1);
    // console.log('mappings',mappings);
    // console.log('selectedConverion', selectedConversion);

    if (
      !integrationMapping &&
      (!selectedConnection ||
        !resource1 ||
        !mappings ||
        Object.keys(mappings).length === 0)
    ) {
      console.error('Error: Missing required fields for saving mapping.');
      toast(`Integrations, Resources or Columns Fields are required !`, {
        type: 'error',
        position: 'top-center',
        hideProgressBar: 'true',
        theme: 'colored',
      });
      return;
    }

    setIsMappingSaving(true);

    try {
      const response = await api.post('/integrations/save-mapping', {
        integrationconnection: selectedConnection?.value,
        conversion: conId || list,
        resource1: resource1,
        resource2: resource2,
        resource3: resource3,
        resource4: resource4,
        mapping: mappings,
      });

      if (response?.status === 201) {
        setIntegrationMapping(response.data);
      } else {
        console.error('Failed to save mapping:', response);
      }
    } catch (error) {
      console.error('Error saving mapping:', error);
    } finally {
      setIsMappingSaving(false);
    }
  }, [
    integrationMapping,
    selectedConnection,
    resource1,
    resource2,
    resource3,
    resource4,
    mappings,
  ]);

  const handleDeleteMapping = useCallback(async () => {
    setIsMappingDeleting(true);

    try {
      const response = await api.delete(
        `/integrations/mapping/${integrationMapping._id}`,
      );
      if (response.status === 200) {
        // console.log('Mapping deleted successfully');
        setFields(null);
        setMappings(null);
        setSelectedFieldsOptions(null);
      } else {
        console.error('Failed to delete mapping');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsMappingDeleting(false);
      setSelectedConnection(null);
      setIntegrationMapping(null);
      setFields(null);
      setMappings(null);
    }
  }, [
    integrationMapping,
    setIsMappingDeleting,
    setFields,
    setMappings,
    setSelectedFieldsOptions,
    setSelectedConnection,
    setIntegrationMapping,
  ]);

  const handleDeleteField = useCallback(
    async (field) => {
      setIsMappingDeleting(true);
      try {
        const response = await api.delete(
          `/integrations/mapping/field/${
            integrationMapping._id
          }/${encodeURIComponent(field)}`,
        );
        if (response.status === 200) {
          fetchIntegrationMapping(selectedConversion?._id);
        } else {
          console.error('Failed to delete mapping field');
        }
      } catch (error) {
        console.error('Failed to delete field:', error);
      } finally {
        setIsMappingDeleting(false);
      }
    },
    [
      integrationMapping,
      setIsMappingDeleting,
      fetchIntegrationMapping,
      selectedConversion,
    ],
  );

  const fetchActiveConnections = useCallback(
    async (userId) => {
      setIsConnectionsLoading(true);
      try {
        const response = await api.get(
          `/integrations/${userId}/active-connection`,
        );

        if (response.status === 204 || response.data.message === 'No content') {
          setConnections([]);
        } else if (response.data) {
          const formattedConnections = response.data.map((conn) => ({
            value: conn._id,
            label: conn.integration.name,
            authDetails: conn.authDetails,
          }));
          setConnections(formattedConnections);
        } else {
          setConnections(null);
        }
      } catch (error) {
        console.error('Error fetching active connections:', error);
        setConnections(null);
      } finally {
        setIsConnectionsLoading(false);
      }
      // If userId is a prop or state that this callback depends on, it should be included in the dependencies array
    },
    [setIsConnectionsLoading, setConnections],
  );

  const handleFieldsFetched = useCallback(
    (fields) => {
      setMappings(null);
      setFields(fields);
    },
    [setFields, setMappings],
  );

  const handleConnectionChange = useCallback(
    (selectedOption) => {
      setSelectedConnection(selectedOption);
      setFields([]);
      setMappings([]);
    },
    [setSelectedConnection, setFields, setMappings],
  );

  const [refreshConversionHistory, setRefreshConversionHistory] =
    useState(false);

  useEffect(() => {
    // console.log('useEffect called')

    if (integrationMapping && connections) {
      const connectionFromDB = connections?.find(
        (connection) =>
          connection.value === integrationMapping?.integrationconnection?._id,
      );
      setSelectedConnection(connectionFromDB);
      // console.log('connectionFromDB', connectionFromDB);
    }

    setSelectedConnection(null);
    setIntegrationMapping(null);
    setFields([]);
    setMappings([]);

    //integrationMapping, connections, isMappingDeleting,
  }, [window.location.search, refreshConversionHistory]);

  useEffect(() => {
    let userId = JSON.parse(localStorage.getItem('user'))?._id;
    fetchActiveConnections(userId);
  }, []);

  const [integrationLogs, setIntegrationLogs] = useState([]);

  const {
    list,
    setListItems,
    openSideBar,
    setOpenSideBar,
    setFetchConversions,
  } = useContext(ListContext);
  const { setUserDetails, userDetails } = useContext(AuthContext);

  const { userPlan, search, handleValidatePlan } = useContext(PlansContext);

  const { width } = useWindowDimensions();
  const [step, setStep] = useState('');
  const [show, setShow] = useState(false);
  const [maxSizeErr, setMaxSizeErr] = useState(false);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [tableAttributes, setTableAttributes] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [data, setData] = useState(null);
  const [loadingConversionData, setLoadingConversionData] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [file, setfile] = useState('');
  const [convertedFile, setConvertedfile] = useState([]);
  const [loading, setLoading] = useState([]);
  const [appendedModal, setAppendedModal] = useState(false);
  const [googleSheetShow, setGoogleSheetShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [uploadTextShow, setUploadTextShow] = useState(false);
  const [merge, setMerge] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [entireWebsite, setEntireWebsite] = useState(false);
  const [noOfPages, setNoOfPages] = useState(2);
  const [conId, setConId] = useState('');
  const [open, setOpen] = useState(false);
  const [sheetDetailsWrite, setSheetDetailsWrite] = useState(null);
  const [sheetDetails, setSheetDetails] = useState(null);
  const [updateSheetLoading, setUpdateSheetLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [totalRecords ,setTotalRecords]=useState(0)

  const [textCheckBox, setTextCheckBox] = useState(false);
  const [templates, setTemplates] = useState([]);

  const [loadingData, setLoadingData] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleGoogleShow = () => setGoogleSheetShow(!googleSheetShow);
  const handleUpload = () => setUploadShow(!uploadShow);
  const handleUploadText = () => setUploadTextShow(!uploadTextShow);
  const handleEditModal = () => setEditModal(!editModal);

  const [conversionHistory, setConversionHistory] = useState([]);
  const [indexEdit, setIndexEdit] = useState(-1);
  const [deleteRowLoading, setDeleteRowLoading] = useState(-1);

  const socket = io(process.env.REACT_APP_SOCKET_IO_URL || process.env.SOCKET_IO_URL || 'http://localhost:4000', {
    transports: ['websocket'],
    withCredentials: true,
  });

  socket.emit('join', JSON.parse(localStorage.getItem('user'))?._id);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    setConId(id);
    getSingleConverion(id);
  }, [window.location.search, list]);

  useEffect(() => {
    const handleTaskComplete = (data) => {
      // console.log('Task completed:', data);
      setRefreshConversionHistory((prev) => !prev);
      // toast.info(
      //   `Task completed on file: ${data?.taskData?.file?.originalname}`,
      // );
      // Direct state update example
      // setTasks(prevTasks => [...prevTasks, data.taskData]);
    };

    const handleTaskError = (errorData) => {
      console.error('Task failed:', errorData.error);
      setRefreshConversionHistory((prev) => !prev);
      // toast.error(`Task failed on file: ${errorData?.error}`);
      // Direct state update example
      // setErrorTasks(prevErrorTasks => [...prevErrorTasks, errorData.error]);
    };

    socket.on('file-conversion-success', handleTaskComplete);
    socket.on('file-conversion-error', handleTaskError);

    return () => {
      socket.off('file-conversion-success', handleTaskComplete);
      socket.off('file-conversion-error', handleTaskError);

      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (
      connections &&
      integrationMapping &&
      integrationMapping?.message !== 'No Content'
    ) {
      const selectedConnectionObj = connections?.find(
        (connection) =>
          connection.value === integrationMapping?.integrationconnection?._id,
      );

      if (selectedConnectionObj) {
        handleConnectionChange(selectedConnectionObj);
      }
    }
    // console.log('selectedConnection', selectedConnection);
  }, [integrationMapping]);


  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    setConId(id)
    const isclick= localStorage.getItem("convClick")

    if(isclick === "noClick"){
       getConversionDataById();
    }   
  }, [window.location.search, localStorage.getItem("convClick")]);

  const getConversionDataById = async (set = false) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');

    if (id && id !== 'noPer') {
      try {
        setLoadingConversionData(true);
        
        const res = await api.get(`/conversion/getData/${id}?page=${currentPage+1}&limit=1000&sortBy=${sortBy}&sortOrder=${sortDirection}`);
        
        if (res?.data) {
          setLoadingConversionData(false);
          setTableHeaders(res?.data?.data[0]?.tableHeaders);
          setTableAttributes(res?.data?.data[0]?.tableAttributes);
          
          setfile({
            name: res?.data?.csvFileName,
            size: res?.data?.csvFileSize,
          });

          setTableData(res?.data?.data[0]?.tableData);
          setStep('step2');
        }
      } catch (err) {
        console.error("Error fetching conversion data:", err);
        setLoadingConversionData(false);
        // Handle error appropriately - maybe show an error message to user
      }
    }
  };

  useEffect(() => {
    refreshMainTableAsHistoryUpdates(true);
  }, [refreshConversionHistory]);

  useEffect(() => {
    const controller = new AbortController();
    if ((conId == null || conId === '') && !list) {
      return;
    }

    const updateHistory = () => {
      getConversionHistory(controller.signal);
      fetchIntegrationLogs(controller.signal);
      // setRefreshConversionHistory(prev => !prev);
    };

    // Initial call
    updateHistory();

    // const interval = setInterval(() => {
    //   updateHistory();
    // }, 5000);

    return () => {
      // clearInterval(interval);
      controller.abort();
    };
  }, [conId, list, refreshConversionHistory]);

  useEffect(() => {
    if (conId == null || conId === '') {
      return;
    }

    fetchIntegrationMapping(conId);
  }, [conId]);

  const getTemplates = async () => {
    let res = await api.get(`/templates/getTemp`);
    setTemplates(res?.data ? res.data : []   );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const readFile = async (selectedFile, id, templateId, additionalData) => {
    const file = selectedFile;
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (result) => {
        const head = result?.meta?.fields || [];
        let data;
        let dupData = [];
        if (result?.data?.length > 5) {
          data = result?.data?.slice(0, 5);
        } else {
          data = result?.data;
        }

        dupData.push(head);
        data?.forEach((val) => {
          let list = [];

          head?.forEach((val2) => {
            list.push(val[val2]);
          });

          dupData.push(list);
        });

        if (dupData?.length > 0) {
          let userId = JSON.parse(localStorage.getItem('user'))?._id;

          setfile(selectedFile);
          setStep('step2');

          let values = {
            user: userId,
            data: [{ tableHeaders: head, tableData: dupData }],
            csvFileName: selectedFile?.name,
            csvFileSize: selectedFile?.size,
            conversion: id ? id : list,
            sheetDetailsWrite: { empty: '' },
            csvFile: selectedFile,
            templateId: templateId,
            ...additionalData,
          };
          try {
            api.post('/conversion/addData', values).then((res) => {
              if (id) {
              } else {
                getConversionData(true);
              }
            });
          } catch (error) {
            console.log("Error",error);
          }
        }
      },
      header: true,
    });
  };

  const getData = async () => {
    let res = await api.get(`/templates/getDefaultTemplates`);

    if (res?.data?.length > 0) {
      for (const item of res?.data) {
        let userId = JSON.parse(localStorage.getItem('user'))?._id;
        const body = {
          name: item?.title,
          user: userId,
          templateId: item?._id,
        };
        let res2 = await api.post('/conversion', body);

        const file = new File([item?.data], '', {
          type: 'text/csv',
        });
        await readFile(file, res2.data?.createConversion?._id, item?._id);
      }
      api
        .patch('/user/updateDefault', {
          default: true,
        })
        .then(async (res) => {
          setFetchConversions(true);
        });
    }
  };
  const getUsr = async () => {
    let res = await api.get(`/user/userDetails`);

    if (!res?.data?.defaultAdded) {
      await getData();
    }
  };
  useEffect(() => {
    getUsr();
  }, []);

  const handleChangeCsv = (e) => {
    let file = e.target.files[0];
    // if (file?.size / 1024 / 1024 > 0.1) {
    //   setMaxSizeErr(true);
    //   toast(`Maximum file size should be ${userPlan[0]?.maxFileSize} MB`, {
    //     type: 'warning',
    //   });
    // } else {
    readFile(file);
    // }
  };
  useEffect(() => {
    if (tableData && selectedConversion?.name) {
      // console.log('starting joyride');
      if (step === 'step1') {
        // setRun(true);
        setRun(false);
        // setJoyride1(true);
        //setJoyride1(false);
      } else {
        //setJoyride1(false);
      }
      setTimeout(() => {
        // console.log('Delayed for 1 second.');
        // setRun(true);
        setRun(false);
      }, '500');
    }
  }, [tableData]);

  useEffect(() => {
    if (step === 'step1') {
      // setRun(true);
      setRun(false);
      // setJoyride1(true);
      //setJoyride1(false);
    } else {
      //setJoyride1(false);
    }
    setTimeout(() => {
      // console.log('Delayed for 1 second.');
      // setRun(true);
      setRun(false);
    }, '500');
  }, []);
  const getConversionData = async () => {
    if (list && list !== 'noPer') {
      try {
        setLoadingConversionData(true);
  
        // Fetch data from the API
        let res = await api.get(
          `/conversion/getData/${list}?page=${currentPage + 1}&limit=10&sortBy=${sortBy}&sortOrder=${sortDirection}`
        );
        setData(res?.data);
        setTotalRecords(res?.data?.pagination?.totalItems)
        setLastPage(Math.ceil(res?.data?.pagination?.totalItems / 1000)-1)

        if (res?.data) {
          setTableHeaders(res?.data?.data[0]?.tableHeaders);
          setTableAttributes(res?.data?.data[0]?.tableAttributes);
          setfile({
            name: res?.data?.csvFileName,
            size: res?.data?.csvFileSize,
          });
          setTableData(res?.data?.data[0]?.tableData);
          setStep('step2');
        } else {
          // Handle cases when no data is returned
          resetTableData();
          setStep('step1');
        }
      } catch (error) {
        console.error("Error fetching conversion data:", error);
        resetTableData();
        setStep('step1');
      } finally {
        // Reset loading states
        setLoadingConversionData(false);
        setLoadingData('');
        setTextCheckBox(false);
        setMerge(false);
        setUpdateSheetLoading(false);
      }
    }
  };
  
  // Reset table data helper function
  const resetTableData = () => {
    setfile(null);
    setTableHeaders(null);
    setTableAttributes(null);
    setTableData(null);
  };
  
  // Run the API call when dependencies change
  useEffect(() => {
    getConversionData();
  }, [list, currentPage, sortBy, sortDirection]);
  



  const refreshMainTableAsHistoryUpdates = async (set = false) => {
    if (list && list !== 'noPer') {
      try {
        let res = await api.get(`/conversion/getData/${list}`);
        setData(res?.data);

        if (res?.data) {
          setTableHeaders(res?.data?.data[0]?.tableHeaders);
          setTableAttributes(res?.data?.data[0]?.tableAttributes);
          setfile({
            name: res?.data?.csvFileName,
            size: res?.data?.csvFileSize,
          });

          setTableData(res?.data?.data[0]?.tableData);
          // console.log("test3")
          setStep('step2');
          //setJoyride1(false);
        } else {
          setfile(null);
          setTableHeaders(null);
          setTableAttributes(null);
          setTableData(null);
          setStep('step1');
          // setJoyride1(true);
          //setJoyride1(false);
        }
      } catch (error) {
        console.log("Error",error);
      }
      setLoadingData('');
      // setTextCheckBox(false);
      // setMerge(false);
      setUpdateSheetLoading(false);
    }
  };

  useEffect(() => {
    handleValidatePlan();
  }, []);

  const getSingleConverion = async (id) => {
    id = id || list;
    let userId = JSON.parse(localStorage.getItem('user'))?._id;
    let res = await api.get(`/conversion/all-notes/${userId}`);

    if (id) {
      const selectedConversion = res.data.getAllConversion.find(
        (conversion) => conversion._id === id,
      );
      setSelectedConversion(selectedConversion);
      //console.log('selectedConversion',selectedConversion);
    } else {
      setSelectedConversion('Conversion');
    }
  };

  const getConversionHistory = useCallback(
    async (signal) => {
      setIsTransformationHistoryLoading(true);
      const userId = JSON.parse(localStorage.getItem('user'))?._id;
      const params = { userId: userId, conversionId: conId || list };

      try {
        const res = await api.get(`/conversion/history/getConversionHistory`, {
          params: params,
          signal: signal,
        });

        if (res?.data?.status === 'success' && res?.data?.data) {
          setConversionHistory(res.data.data);
          if (
            res.data.data?.some(
              (convHistory) => convHistory?.status === 'Working',
            )
          ) {
            setTimeout(() => getConversionHistory(signal), 15000);
          }
        } else {
          setConversionHistory([]);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // console.log('Request was aborted');
        } else {
          if (error.code !== 'ERR_CANCELED') {
            setConversionHistory([]);
          }
        }
      } finally {
        setIsTransformationHistoryLoading(false);
      }
    },
    [conId, list, setConversionHistory],
  );

  const [isIntegrationLogsLoading, setIsIntegrationLogsLoading] =
    useState(false);
  const [isTransformationHistoryLoading, setIsTransformationHistoryLoading] =
    useState(false);

  const fetchIntegrationLogs = useCallback(
    async (signal) => {
      setIsIntegrationLogsLoading(true);
      const userId = JSON.parse(localStorage.getItem('user'))?._id;
      const conversionId = conId || list;

      if (conversionId && conversionId !== 'null') {
        try {
          const response = await api.get(
            `/integrations/logs/${userId}/${conversionId}`,
            { signal },
          );
          setIntegrationLogs(response.data);
        } catch (error) {
          // Only log error if it's not a cancellation
          if (error.code !== 'ERR_CANCELED') {
            console.error('Error fetching integration logs:', error);
            setIntegrationLogs([]);
          }
        } finally {
          setIsIntegrationLogsLoading(false);
        }
      }
    },
    [conId, list, setIntegrationLogs],
  );
  const handleReload = () => {
    try {
      api.delete(`/conversion/delData/${list}`);
      setData(null);
      setConvertedfile(convertedFile?.filter((el) => el?.list !== list));
      handleClose();
      setStep('step1');
    } catch (error) {
      console.log("Error",error);
    }
  };


  
  const downloadExcel = async() => {
    let res = await api.get(`${list}`);
    const ws = XLSX.utils.json_to_sheet(res?.data?.convertedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Transformed-Data');
    XLSX.writeFile(wb, 'Data.xlsx');
  };

  const downloadCSV = async() => {
    let res = await api.get(`/conversion/getData/${list}`);

    if (!res?.data?.convertedData?.length) return;

    const csvContent = Papa.unparse(res?.data?.convertedData);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'Data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadJSON = async() => {
    let res = await api.get(`/conversion/getData/${list}`);

    if (!res?.data?.convertedData?.length) return;

    const jsonString = JSON.stringify(res?.data?.convertedData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Data.json');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const prepareUploadData = (
    selectedFiles,
    loadingTemp,
    returnRowsLimit,
    model,
    isBackground,
    processUrlsProp,
    conversionId,
    mergeModal,
    templateDefaults,
  ) => {
    setLoadingData(loadingTemp);
    setLoading([...loading, list]);

    let allFiles = convertedFile?.filter((el) => el?.list !== list);
    setConvertedfile([...allFiles, { name: selectedFiles[0]?.name, list }]);

    let finalTxtData = [];

    tableData?.forEach((el) => {
      let obj = {};
      tableHeaders?.forEach((hd, i) => {
        obj[hd] = el[i];
      });
      delete obj['Date/Time'];
      delete obj['FileName'];
      finalTxtData.push(obj);
    });

    let formData = new FormData();

    formData.append('isBackground', isBackground);
    formData.append(
      'processUrls',
      `${
        processUrlsProp || textCheckBox || loadingTemp === '2' ? true : false
      }`,
    );
    formData.append('pagination', textCheckBox && pagination);
    formData.append('entireWebsite', textCheckBox && entireWebsite);
    textCheckBox &&
      pagination &&
      formData.append('pagination_max_iteractions', noOfPages);
    formData.append(
      'returnRowsLimit',
      `${returnRowsLimit ? returnRowsLimit : null}`,
    );
    formData.append('merge', `${mergeModal || merge ? true : false}`);
    formData.append('search', `${searchQuery}`);
    formData.append('model', `${model ? model : 1}`);
    formData.append('id', conversionId || conId || list);
    // Append each selected file individually
    selectedFiles?.forEach((file, index) => {
      formData.append(`files`, file);
    });

    formData.append('templateDefaults', templateDefaults);

    return formData;
  };

  const handleAPIResponse = async (apiResponse) => {
    let res = apiResponse?.data;

    handleValidatePlan();
    if (res?.status_code) {
      document.getElementById('upload_file_convert').value = '';
      toast(`${res?.detail}`, { type: 'error' });
      if (loading?.length > 0) {
        setLoading(loading?.filter((el) => el !== list));
      }
      setLoadingData('');
    } else if (res?.data?.length > 0) {
      setUploadShow(false);
      setUploadTextShow(false);
      getConversionData();
      document.getElementById('upload_file_convert').value = '';
      setAppendedModal(true);
      setLoading(loading?.filter((el) => el !== list));
    } else if (res?.data?.length === 0) {
      setLoadingData('');
    }
  };

  const handleUploadFile = async (
    selectedFiles,
    loadingTemp,
    returnRowsLimit,
    model,
    isBackground,
    processUrlsProp,
    conversionId,
    mergeModal,
    templateDefaults = false,
  ) => {
    let formData = prepareUploadData(
      selectedFiles,
      loadingTemp,
      returnRowsLimit,
      model,
      isBackground,
      processUrlsProp,
      conversionId,
      mergeModal,
      templateDefaults,
    );
    //formData.append('isBackground', isBackground);

    let apiEndpoint = '/conversion/uploadFilesToDb';

    let processStatusId; // Will store the ID of the created process status for patching later

    try {
      // console.log('formData', formData);

      if (isBackground) {
        let temperedFile =
          selectedFiles?.length > 0
            ? {
                ...selectedFiles?.[0],
                name: selectedFiles?.map((fi) => fi?.name)?.toString(),
              }
            : file;
        toast(<NotificationMsg file={temperedFile} />, {
          hideProgressBar: true,
          pauseOnFocusLoss: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: false,
        });
      }

      let apiResponse = await api.post(apiEndpoint, formData);

      // setRefreshConversionHistory(!refreshConversionHistory);
      setRefreshConversionHistory((prev) => !prev);

      await handleAPIResponse(apiResponse);
    } catch (error) {
      if (processStatusId) {
        await api.patch(
          `/conversion/history/patchConversionHistory/${processStatusId}`,
          {
            status: 'Error',
            endTime: new Date(),
            errorMessage:
              error?.response?.data?.error ||
              error?.response?.data?.message ||
              error?.message ||
              'Error',
          },
        );
        // setRefreshConversionHistory(!refreshConversionHistory);
        setRefreshConversionHistory((prev) => !prev);
      }

      toast(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.message ||
          'Error',
        {
          type: 'error',
        },
      );
      setUploadShow(false);
      if (loading?.length > 0) {
        setLoading(loading?.filter((el) => el !== list));
      }
      setLoadingData('');
    }
  };

  const handleChangeUploadFile = async (
    e,
    returnRowsLimit,
    model,
    isBackground,
  ) => {
    const files = e.target.files;
    if (
      Object.values(files)?.some(
        (file) => file?.size / 1024 / 1024 > userPlan[0]?.maxFileSize,
      ) &&
      userPlan?.length > 0
    ) {
      toast(`Maximum file size should be ${userPlan[0]?.maxFileSize} MB`, {
        type: 'warning',
      });
    } else {
      if (isBackground) {
        handleUploadFile(
          Object.values(files),
          '1',
          returnRowsLimit,
          model,
          isBackground,
        ); // '1' indicates background loading
        //toast.info(`Background process has started on file: ${file.name}`);
      } else {
        handleUploadFile(
          Object.values(files),
          '0',
          returnRowsLimit,
          model,
          isBackground,
        );
      }
    }
  };

  const handleDeleteRow = async (row, index, type, rowType) => {
    setDeleteRowLoading(`${rowType + index}`);
    try {
      let deleteRow = await api.delete(
        `/conversion/${conId || list}/delete?row=${index}&convertedData=${
          type !== 'csv'
        }`,
      );
      if (deleteRow) {
        await getConversionData();
        setDeleteRowLoading(-1);
      }
    } catch (error) {
      setDeleteRowLoading(-1);
      console.log('Error: ', error);
    }
  };

  const handleWriteReportSelected = (sheetDetails) => {
    setSheetDetailsWrite(sheetDetails);
    setUpdateSheetLoading(true);
    api
      .patch(`/conversion/updateData/${conId || list}`, {
        sheetDetailsWrite: sheetDetails,
      })
      .then((res) => {
        getConversionData();
        handleGoogleShow();
      });
  };

  const unlinkGoogleSheet = (type) => {
    setSheetDetailsWrite(null);
    setSheetDetails(null);

    api
      .patch(`/conversion/updateData/${conId || list}`, {
        sheetDetailsWrite: { empty: '' },
      })
      .then((res) => {
        getConversionData();
        if (type === 'disconnectGoogle') {
          setGoogleSheetShow(false);
        }
      });
  };

  useEffect(() => {
    if (data?.sheetDetailsWrite && data?.sheetDetailsWrite?.empty === '') {
      setSheetDetails(null);
    } else if (data?.sheetDetailsWrite) {
      setSheetDetails(data?.sheetDetailsWrite);
    } else {
      setSheetDetails(null);
    }
  }, [data]);

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((clipboardText) => {
        if (clipboardText?.length > 0) {
          const file = new File([clipboardText], '', {
            type: 'text/csv',
          });
          readFile(file);
        }
      })
      .catch((error) => {
        console.log('Failed to read clipboard data:', error);
      });
  };

  // Replace the existing convertData function with this more robust version
  const convertData = (date) => {
    try {
      if (!date) return '';
      
      // First try parsing as ISO date
      let parsedDate = new Date(date);
      
      // If invalid, try adding UTC
      if (isNaN(parsedDate.getTime())) {
        parsedDate = new Date(date + ' UTC');
      }
      
      // If still invalid, return empty string
      if (isNaN(parsedDate.getTime())) {
        return '';
      }
      
      return parsedDate.toLocaleString();
    } catch (error) {
      console.warn('Error converting date:', error);
      return '';
    }
  };
  const handleSort = (columnIndex ,el) => {
    // console.log(sortColumn)
    setSortBy(el)
    if (sortColumn === columnIndex) {
      // Toggle direction if already sorted
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Sort new column, default to ascending
      setSortColumn(columnIndex);
      setSortDirection("asc");
    }
  
    const sortedData = [...tableData].sort((a, b) => {
      const valueA = a[columnIndex];
      const valueB = b[columnIndex];
  
      if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
      if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  
    setTableData(sortedData); // Update table state
  };
  
  const getImgName = (img) => {
    let imgs = img?.split('/');
    return imgs?.length > 0 ? imgs[imgs.length - 1] : '';
  };

  const [rowTypeSample, setRowTypeSample] = useState(false);
  const [addRowModal, setAddRowModal] = useState(false);
  const [addRowLoading, setAddRowLoading] = useState(false);

  const [appendColumn, setAppendColumn] = useState(false);
  const [addColumnModal, setAddColumnModal] = useState(false);
  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [editColumnTitleLoading, setEditColumnTitleLoading] = useState(false);
  const [editColumnTitle, setEditColumnTitle] = useState(false);

  const [dropDownColumn, setDropDownColumn] = useState(-1);
  const [deleteColumnLoading, setDeleteColumnLoading] = useState(-1);
  const [selectedColumnName, setSelectedColumnName] = useState('');
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);

  const openAddRowModal = (sampleData = false) => {
    if (tableHeaders?.length > 2) {
      setRowTypeSample(sampleData);
      setAddRowModal(true);
    } else {
      toast(
        'Please add column first. Use three dots to the right of DATE/TIME header to add a column.',
        { type: 'warning' },
      );
    }
  };
  const openAddColumnModal = (end = false, name) => {
    setSelectedColumnName(name);
    setAppendColumn(end);
    setAddColumnModal(true);
  };

  const handleClickOutside = (event) => {
    // console.log('clicked outside', event.target);
    if (event.target.className !== 'dotsIcon') {
      setDropDownColumn(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const [openPlan, setOpenPlan] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);

  function extractDateFromString(name) {
    // Define a regular expression pattern to match the date format
    const dateRegex = /\b(\d{4}-\d{2}-\d{2} \d{2}:\d{2})\b/;
    // Use the match method to find the date in the string
    const matchResult = name?.match(dateRegex);
    // Check if a match is found
    if (matchResult && matchResult[1]) {
      // Parse the input date string using Moment.js
      const inputMoment = moment(
        new Date(matchResult[1] + ' UTC')?.toLocaleString(),
        'M/D/YYYY, h:mm:ss A',
      );

      // Format the date in the desired custom format
      const formattedDate = inputMoment.format('YYYY-MM-DD HH:mm');
      return `${name?.split(matchResult[1])?.[0]} ${formattedDate}`;
    } else {
      // Return null if no date is found
      return name;
    }
  }
  const grabData = (value) => {
    const {
      Pagination,
      merge,
      model,
      pagination_max_iteractions,
      processUrls,
      entireWebsite,
      search,
    } = value ?? {};
    return {
      Pagination,
      merge,
      model,
      pagination_max_iteractions,
      processUrls,
      entireWebsite,
      search,
    };
  };



  //onPageChange
  const onPageChange=(e)=>{
    // console.log(e.selected ,"selected")
setCurrentPage(e.selected)
  }
  return (
    <>
      <>
        <div className="main w-100">
          {/* <div className={width <= 605 ? "hideSideBar" : "sidebar"}> */}
          <div className="sidebar">
            <Sidebar {...{ openPlan, setOpenPlan, handleUploadFile }} />
          </div>
   {
    templates?.length===0 ? <Loader />:
  <>
   {list === 'noPer' ? (
            <div className="d-flex h-100 align-items-center justify-content-center justify-items-center">
              <h2 style={{ marginLeft: !openSideBar ? '300px' : '0px' }}>
                You do not have an access to this transformation or the
                transformation not exists
              </h2>
            </div>
          ) : (
            <>
              {/* <Joyride
                continuous
                callback={onJoyrideCallback}
                // completeCallback={handleJoyrideEnd}
                run={run}
                steps={joyride1 === true ? s1 : s2}
                hideCloseButton
                // scrollToFirstStep
                showSkipButton
                showProgress
              /> */}
              <Header showDemo />
              <div className={`${
            !openSideBar ? 'header_open' : 'header_close'
          } header`}>
                <div>
                  <div>
                    <Tabs
                      style={{
                        marginLeft: 0,
                        // maxWidth: 'calc(100% - 140px)',
                      }}
                      defaultActiveKey="conversion"
                      id="uncontrolled-tab-example"
                      onSelect={(tabSelected) => {
                        if (tabSelected === 'history') {
                          getConversionHistory();
                        }
                      }}
                    >
                      <Tab
                        eventKey="conversion"
                        // title="Conversion"
                        title={
                          <p id="steponejoy2">
                            {selectedConversion
                              ? extractDateFromString(selectedConversion?.name)
                              : 'Conversion'}
                          </p>
                        }
                        className="conversion"
                      >
                        {userPlan?.length > 0 && (
                          <div
                            style={{
                              fontSize: '18px',
                              marginTop: '10px',
                              marginLeft: '15px',
                              fontWeight: 'bold',
                            }}
                          >
                            {search.remainingUploads !== null &&
                              search.totalUploads !== null && (
                                <span className="step-2">
                                  Transformations left in this month:{' '}
                                  {search?.remainingUploads} of{' '}
                                  {search?.totalUploads}
                                </span>
                              )}
                          </div>
                        )}
                        {step === 'step1' && list ? (
                          <div className="Home_content_main">
                            <div className="home_content">
                              {/* <h3 className="home_content_heading">
                                Use Template, Upload CSV file or Paste clipboard
                                from spreadsheet (Excel, CSV, google Sheets) to
                                define sample of data you need (2-3 rows of
                                sample data needed)
                              </h3> */}
                              {/* <p className="upload_title">
                                Upload sample of data as CSV file of by copying
                                from a spreadsheets. It is recommended to upload
                                from 2 to 5 sample rows of desired data.
                              </p> */}

                              <div
                                className={`upload_csv_file_main ${
                                  maxSizeErr && 'maxSizeError'
                                }`}
                                // style={{
                                //   height: `${100 - 34}vh`,
                                // }}
                              >
                                {search?.remainingUploads === 0 && (
                                  <div
                                    className={`upload_error ${
                                      maxSizeErr && 'labelError'
                                    }`}
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <strong>Important:</strong> You have used
                                    your monthly number of uploads.
                                  </div>
                                )}
                                <Form.Control
                                  className="rounded-0 uploadField "
                                  type="file"
                                  name="image"
                                  id="upload_csv"
                                  onChange={(e) => {
                                    // console.log('-----', e);
                                    handleChangeCsv(e);
                                  }}
                                  multiple={false}
                                  accept=".csv"
                                />

                                {/* <h3> */}
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<HiArrowDown />}
                                  >
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Option 1. Choose a Template
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {/* </h3> */}
                                    {/* <div
                                  className="d-flex mt-2"
                                  id="joyonestep5"
                                  onClick={() => setOpen(!open)}
                                >
                                  <img src={templateIcon} alt="template" />
                                  <p className="green-text-12px ms-3 mt-2 me-2">
                                    Extract and monitor data on the <br /> web
                                    with 2 clicks
                                  </p>
                                  <div
                                    className={`mt-1 ${!open ? 'rotate' : ''}`}
                                  >
                                    <img src={arrowIcon} alt="template" />
                                  </div>
                                </div> */}

                                    <>
                                      {width <= 914 ? (
                                        <div className="drop-down-container-sm shadow">
                                          {templates?.map((val) => (
                                            <div
                                              className="d-flex mb-2"
                                              onClick={() => {
                                                if (val?.data) {
                                                  const file = new File(
                                                    [val.data],
                                                    '',
                                                    {
                                                      type: 'text/csv',
                                                    },
                                                  );
                                                  setOpen(false);
                                                  readFile(
                                                    file,
                                                    undefined,
                                                    val?._id,
                                                    grabData(val),
                                                  );
                                                }
                                                else{
                                                  const allHeaders = val.tableDataField[0]?.tableHeaders.join(",") || ""; // Combine all headers with commas
                                                  const file = new File(
                                                    [allHeaders], // Use all headers
                                                    '',
                                                    {
                                                      type: 'text/csv',
                                                    },
                                                  );
                                                
                                                  setOpen(false);
                                                
                                                  readFile(
                                                    file,
                                                    undefined,
                                                    val?._id,
                                                    grabData(val),
                                                  );
                                                }
                                              }}
                                            >
                                              <div className="img-container">
                                                <div class="circle-container">
                                                  <img
                                                    src={`https://new-app.datatera.io/public/${getImgName(
                                                      val?.img,
                                                    )}`}
                                                    alt="template"
                                                    height={34}
                                                    width={34}
                                                  />
                                                </div>
                                              </div>
                                              <div>
                                                <p className="title ms-2">
                                                  {val?.title}
                                                </p>
                                                <div className="ms-2 margin">
                                                  <h3
                                                    className="description-data mt-2"
                                                    style={{
                                                      whiteSpace: 'pre-line',
                                                    }}
                                                  >
                                                    {val?.description}
                                                  </h3>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <div className="drop-down-container shadow mt-2">
                                          {templates?.map((val) => (
                                            <div
                                              className="d-flex  col-md-12 pt-3 ps-1"
                                              onClick={() => {
                                                if (val?.data) {
                                                  const file = new File(
                                                    [val.data],
                                                    '',
                                                    {
                                                      type: 'text/csv',
                                                    },
                                                  );
                                                  setOpen(false);
                                                  readFile(
                                                    file,
                                                    undefined,
                                                    val?._id,
                                                    grabData(val),
                                                  );
                                                }
                                                else{
                                                  const allHeaders = val.tableDataField[0]?.tableHeaders.join(",") || ""; // Combine all headers with commas
  const file = new File(
    [allHeaders], // Use all headers
    '',
    {
      type: 'text/csv',
    },
  );

  setOpen(false);

  readFile(
    file,
    undefined,
    val?._id,
    grabData(val),
  );
                                                }
                                              }}
                                            >
                                              <div className="img-container">
                                                <div class="circle-container">
                                                  <img
                                                    src={`https://new-app.datatera.io/public/${getImgName(
                                                      val?.img,
                                                    )}`}
                                                    alt="template"
                                                    height={34}
                                                    width={34}
                                                  />
                                                </div>
                                              </div>

                                              <p className="title col-md-3 ms-2">
                                                {val?.title}
                                              </p>
                                              <div className="col-md-8">
                                                <h3 className="description"></h3>
                                                <br />
                                                <h3
                                                  className="description-data"
                                                  style={{
                                                    whiteSpace: 'pre-line',
                                                  }}
                                                >
                                                  {val?.description}
                                                </h3>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<HiArrowDown />}
                                  >
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {/* <h3> */}
                                      Option 2. Upload an example of the data
                                      you need.
                                      {/* </h3> */}
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div>
                                      <p>
                                        The example should contain several (one
                                        to five) rows of ideal sample data you
                                        want to get.
                                      </p>
                                    </div>
                                    <div className="transformation-buttons-wrapper">
                                      <label
                                        for="upload_csv"
                                        className="upload_csv_btn btn-dark"
                                        // {
                                        //   search?.remainingUploads < 1
                                        //     ? 'upload_csv_btn m-1 disabled'
                                        //     : 'upload_csv_btn m-1'
                                        // }
                                        id="joyonestep3"
                                      >
                                        Upload CSV sample
                                        <p
                                          className={`upload_error m-0`}
                                          style={{
                                            position: 'absolute',
                                            bottom: 1,
                                          }}
                                        >
                                          <b style={{ fontSize: '12px' }}>
                                            {/* Max 5 first rows will be used as a sample. */}
                                            {/* {userPlan?.[0]?.maxFileSize}Mb. */}
                                            {/* {`${
                                    className={
                                      search?.remainingUploads < 1
                                        ? 'upload_csv_btn m-1 disabled'
                                        : 'upload_csv_btn m-1'
                                    }
                                    style={{ position: 'relative' }}
                                    id="joyonestep3"
                                  >
                                    Upload CSV file
                                    <p
                                      className={`upload_error m-0 ${
                                        maxSizeErr && 'labelError'
                                      }`}
                                      style={{
                                        position: 'absolute',
                                        bottom: 1,
                                      }}
                                    >
                                      <b style={{ fontSize: '12px' }}>
                                        {`${
                                          userPlan?.length > 0 &&
                                          `Max file size - ${userPlan[0]?.maxFileSize} mb.`
                                        }`} */}
                                          </b>
                                        </p>
                                      </label>

                                      <Button
                                        // disabled={
                                        //   search?.remainingUploads < 1
                                        //     ? true
                                        //     : false
                                        // }
                                        variant="dark"
                                        id="joyonestep4"
                                        onClick={handlePaste}
                                      >
                                        {loading === '4' ? (
                                          <Spinner
                                            animation="border"
                                            variant="secondary"
                                          />
                                        ) : (
                                          'Paste sample data (copy-paste from Excel, CSV, Google Sheets)'
                                        )}
                                      </Button>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<HiArrowDown />}
                                  >
                                    <div
                                      style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {/* <h3> */}
                                      Option 3. Create Transformation from
                                      scratch.
                                      {/* </h3> */}
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="transformation-buttons-wrapper">
                                      <Button
                                        variant="dark"
                                        onClick={() => {
                                          // setLoading('5')
                                          let userId = JSON.parse(
                                            localStorage.getItem('user'),
                                          )?._id;
                                          let values = {
                                            user: userId,
                                            data: [
                                              {
                                                tableHeaders: [[]],
                                                tableData: [[]],
                                              },
                                            ],
                                            csvFileName: '',
                                            csvFileSize: '',
                                            conversion: list,
                                            sheetDetailsWrite: { empty: '' },
                                          };

                                          try {
                                            api
                                              .post(
                                                '/conversion/addData',
                                                values,
                                              )
                                              .then((res) => {
                                                getConversionData(true);
                                              });
                                          } catch (error) {
                                            console.log(error);
                                          }
                                        }}
                                      >
                                        {loading === '5' ? (
                                          <Spinner
                                            animation="border"
                                            variant="secondary"
                                          />
                                        ) : (
                                          'Add blank transformation'
                                        )}
                                      </Button>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                                {/* <h3>Option 3. Create a Blank conversion</h3> */}
                                {/* <div
                                  className={`${width > 1100 ? 'd-flex' : ''}`}
                                >
                                  
                                </div> */}
                              </div>
                            </div>
                          </div>
                        ) : step === 'step2' && list ? (
                          <div className="Home_content_main">
                            <div className="home_content">
                              <div className="d-flex justify-content-between">
                                {/* <h3 className="home_content_heading">
                                  Upload file, text or URLs you want to convert
                                </h3> */}
                                <label
                                  className={`upload_csv_btn m-1 p-1 text-center ${
                                    loadingConversionData ? 'primary-bg' : ''
                                  }`}
                                  style={{
                                    position: 'relative',
                                    minWidth: '150px',
                                    height: width < 900 ? '70px' : '55px',
                                    fontSize: '13px',
                                  }}
                                  onClick={() => {
                                    if (!loadingConversionData) {
                                      if (search?.remainingUploads <= 0) {
                                        if (
                                          userPlan?.reverse()?.[0]?.name ===
                                          'FREE'
                                        ) {
                                          setOpenPlan?.(true);
                                        } else {
                                          setShowLimitModal(true);
                                        }
                                      } else {
                                        // console.log('tableData', tableData);
                                        if (tableHeaders?.length > 2) {
                                          if (tableData?.length > 0) {
                                            setUploadShow(true);
                                          } else {
                                            toast(
                                              'Add at least one sample row',
                                              {
                                                type: 'warning',
                                              },
                                            );
                                          }
                                        } else {
                                          toast(
                                            'Please add column first. Use three dots to the right of DATE/TIME header to add a column.',
                                            { type: 'warning' },
                                          );
                                        }
                                      }
                                    }
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      marginBottom: '10px',
                                    }}
                                  >
                                    Choose File, Text, or Website to transform
                                  </p>
                                  <p
                                    className={`upload_error m-0`}
                                    style={{
                                      position: 'absolute',
                                      bottom: 1,
                                    }}
                                  >
                                    <b
                                      style={{
                                        fontSize: '12px',
                                        lineHeight: 0,
                                      }}
                                    >
                                      {`${
                                        userPlan?.length > 0
                                          ? `(Max file size - ${userPlan?.[0]?.maxFileSize} Mb)`
                                          : '(Max file size - undefined)'
                                      }`}
                                      {/* {convertedFile?.find(
                                        (el) => el?.list === list,
                                      )
                                        ? convertedFile?.find(
                                            (el) => el?.list === list,
                                          )?.name?.length > 18
                                          ? `${convertedFile
                                              ?.find((el) => el?.list === list)
                                              ?.name?.slice(0, 18)}...`
                                          : convertedFile?.find(
                                              (el) => el?.list === list,
                                            )?.name
                                        : `${
                                            userPlan?.length > 0
                                              ? `(Max file size - ${userPlan[0]?.maxFileSize} mb)`
                                              : ''
                                          }`} */}
                                    </b>
                                  </p>
                                </label>
                              </div>
                              {/* <p className="upload_title">
                                Upload file, text or URLs you want to convert to dataset
                              </p> */}

                              {/*<div className="template_csv_main">
                                  <div className="template_csv">
                                    <div className="template_csv_heading_main">
                                      
                                       <Form.Control
                                      className="rounded-0 uploadField"
                                      type="file"
                                      name="upload_file"
                                      id="upload_file_convert"
                                      onChange={(e) =>
                                        handleChangeUploadFile(e)
                                      }
                                      multiple={false}
                                      accept=".csv,.txt,.json,.docx,.png,.jpg,.jpeg,.xlsx,.html,.htm,.wav,.pptx,.xbrl,.xml,.pdf"
                                    />
                                    <label
                                      for="upload_file_convert"
                                      className="upload_file_convert_btn"
                                    >
                                      {loading?.filter((el) => el === list)
                                        ?.length > 0 ? (
                                        <Spinner
                                          animation="border"
                                          variant="secondary"
                                        />
                                      ) : (
                                        "Upload file to convert"
                                      )}
                                    </label> 
                                    </div>
                                  </div>
                                </div>*/}

                              <div className="data_template_main">
                                <div
                                  className={`d-flex mb-2 ${
                                    width < 1078 ? 'flex-column' : ''
                                  }`}
                                >
                                  {/* <p className="data_template_heading d-flex align-items-center">
                                    Data
                                  </p> */}
                                </div>
                                <div
                                  className="tableData"
                                  ref={tableContainerRef}
                                  style={{
                                    width: '100%',
                                    height: `${100 - 42}vh`,
                                    maxHeight: `${100 - 42}vh`,
                                    overflowY: 'auto',
                                  }}
                                >
                                  <Table>
  <thead className="table_heading sticky-header">
    <tr>
      {tableHeaders?.map((el, indxEl) => {
        const isSorted = sortColumn === indxEl; // Check if column is currently sorted
        const isAscending = sortDirection === "asc"; // Current sorting direction

        return (
          <th key={indxEl}>
            <div className="d-flex align-items-center">
              {/* Column Header with Sorting */}
              <p
                className="m-0 text-nowrap"
                onClick={() =>
                  setDropDownColumn(
                    indxEl !== dropDownColumn ? indxEl : -1,
                  )
                }
                style={{ cursor: "pointer", marginRight: "8px" }}
              >
                {el}
              </p>

              {/* Sorting Icons */}
              <div
                onClick={() => handleSort(indxEl ,el)} // Trigger sorting
                style={{ cursor: "pointer" ,marginLeft:"3px" }}
              >
                {isSorted ? (
                  isAscending ? (
                    <HiOutlineSortAscending size={20} color="#007bff"  />
                  ) : (
                    <HiOutlineSortDescending size={20} color="#007bff" />
                  )
                ) : (
                  <HiOutlineArrowSmDown size={20} color="#6c757d" />
                )}
              </div>

              {/* Existing Settings Dropdown Logic */}
              <div
                className=""
                style={{ position: "relative" }}
                onClick={(e) => {
                  e.preventDefault();
                  const position = e.target.getBoundingClientRect();
                  setDropdownPosition(position);
                  setDropDownColumn(
                    indxEl !== dropDownColumn ? indxEl : -1,
                  );
                }}
              >
                {el.toLowerCase() === "FileName".toLowerCase() ? null : deleteColumnLoading === indxEl ? (
                  <Spinner
                    size="sm"
                    animation="border"
                    variant="secondary"
                    className="ms-2"
                    style={{ position: "absolute" }}
                  />
                ) : (
                  <img
                    style={{ marginLeft: "8px", width: "18px" }}
                    className="dotsIcon"
                    src={settings}
                  />
                )}
                {dropDownColumn === indxEl ? (
                  <div
                    className="dropdown-column"
                    ref={dropdownRef}
                    style={{
                      top: `${dropdownPosition.top + 30}px`,
                      left: `${dropdownPosition.left}px`,
                    }}
                  >
                    {/* Existing dropdown content */}
                    {el.toLowerCase() !== "Date/Time".toLowerCase() && (
                      <div
                        onClick={() => {
                          setSelectedColumnName(el);
                          setSelectedColumnIndex(indxEl);
                          setEditColumnTitle(true);
                        }}
                      >
                        Edit column name and description
                      </div>
                    )}
                    {el.toLowerCase() !== "Date/Time".toLowerCase() && (
                      <div
                        onClick={() => {
                          openAddColumnModal(false, el);
                          setDropDownColumn(-1);
                        }}
                      >
                        Add Column to Left
                      </div>
                    )}
                    <div
                      onClick={() => {
                        openAddColumnModal(true, el);
                        setDropDownColumn(-1);
                      }}
                    >
                      Add Column to Right
                    </div>
                    {el.toLowerCase() !== "Date/Time".toLowerCase() && (
                      <div
                        onClick={async () => {
                          setDropDownColumn(-1);
                          setDeleteColumnLoading(indxEl);
                          try {
                            await api
                              .patch(
                                `/conversion/${data?.conversion}/deleteColumn`,
                                { title: el },
                              )
                              .then(async (res) => {
                                await getConversionData();
                                setDeleteColumnLoading(-1);
                              });
                          } catch (e) {
                            setDeleteColumnLoading(-1);
                            console.log("Error", e);
                          }
                        }}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </th>
        );
      })}
      <th></th>
    </tr>
  </thead>
 
  <tbody className="table_body">
                                      {showSampleData &&
                                        tableData?.map((el, index) => {
                                          return (
                                            <tr style={{color:"#92929d"}} >
                                              {el?.map((value, i) => {
                                                return (
                                                  <>
                                                    {i === 1 ? (
                                                      <td style={{color:"#92929d"}}>
                                                        {convertData(value)}
                                                      </td>
                                                    ) : (
                                                      <td style={{color:"#92929d"}}>{value}</td>
                                                    )}
                                                  </>
                                                );
                                              })}
                                              <td
                                                className="d-flex align-align-items-center justify-content-center"
                                                style={{
                                                  borderBottom:
                                                    '1px solid transparent',
                                                }}
                                              >
                                                <span
                                                  onClick={() => {
                                                    setEditModal(true);
                                                  }}
                                                >
                                                  <AiOutlineEdit
                                                    style={{
                                                      cursor: 'pointer',
                                                      width: '20px',
                                                      height: '23px',
                                                      color: '#92929D',
                                                    }}
                                                    className="me-2"
                                                    onClick={() => {
                                                      setIndexEdit(index);
                                                      setSelectedRow({
                                                        data: el,
                                                        header: tableHeaders,
                                                      });
                                                      setOpenSideBar(
                                                        !openSideBar,
                                                      );
                                                    }}
                                                  />
                                                </span>

                                                {deleteRowLoading ===
                                                `samp${index}` ? (
                                                  <Spinner
                                                    size="sm"
                                                    animation="border"
                                                    variant="secondary"
                                                    className="ms-2"
                                                  />
                                                ) : null}
                                                {deleteRowLoading !==
                                                `samp${index}` ? (
                                                  <img
                                                    src={deleteImg}
                                                    style={{
                                                      cursor: 'pointer',
                                                      pointerEvents:
                                                        deleteRowLoading !== -1
                                                          ? 'none'
                                                          : '',
                                                    }}
                                                    onClick={() =>
                                                      handleDeleteRow(
                                                        el,
                                                        index,
                                                        'csv',
                                                        'samp',
                                                      )
                                                    }
                                                    alt="delete"
                                                  />
                                                ) : null}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      {data?.convertedData?.map((el, index) => {
                                        return (
                                          <tr style={{ color: '#44444F' }}>
                                            {Object.keys(el).map(function (
                                              detail,
                                              id,
                                            ) {
                                              return (
                                                <>
                                                  {id === 1 ? (
                                                    <td>{convertData(el?.[tableHeaders?.[id]])}</td>
                                                  ) : (
                                                    <td>{el?.[tableHeaders?.[id]]}</td>
                                                  )}
                                                </>
                                              );
                                            })}
                                            <td
                                              className="d-flex align-align-items-center justify-content-center"
                                              style={{
                                                borderBottom:
                                                  '1px solid transparent',
                                              }}
                                            >
                                              <span
                                                onClick={() => {
                                                  setEditModal(true);
                                                }}
                                              >
                                                <AiOutlineEdit
                                                  style={{
                                                    cursor: 'pointer',
                                                    width: '20px',
                                                    height: '23px',
                                                    color: '#92929D',
                                                  }}
                                                  className="me-2"
                                                  onClick={() => {
                                                    setIndexEdit(index);
                                                    setSelectedRow({
                                                      data: el,
                                                      header:
                                                        tableHeaders?.slice(2),
                                                    });
                                                    setOpenSideBar(
                                                      !openSideBar,
                                                    );
                                                  }}
                                                />
                                              </span>
                                              {deleteRowLoading ===
                                              `con${index}` ? (
                                                <Spinner
                                                  size="sm"
                                                  animation="border"
                                                  variant="secondary"
                                                  className="ms-2"
                                                />
                                              ) : null}

                                              {deleteRowLoading !==
                                              `con${index}` ? (
                                                <img
                                                  src={deleteImg}
                                                  style={{
                                                    cursor: 'pointer',
                                                    pointerEvents:
                                                      deleteRowLoading !== -1
                                                        ? 'none'
                                                        : '',
                                                  }}
                                                  onClick={() =>
                                                    handleDeleteRow(
                                                      el,
                                                      index,
                                                      '',
                                                      'con',
                                                    )
                                                  }
                                                  alt="delete"
                                                  className={`delImg `}
                                                />
                                              ) : null}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
 
</Table>
                                  {!showSampleData &&
                                    !data?.convertedData?.length && (
                                      <div className="d-flex justify-items-center text-center mx-auto mt-5 py-5 w-75">
                                        New data will be shown here after
                                        transformations will be finished. You
                                        can see running transformations by
                                        clicking History at the top of the page
                                        new Transformation Name
                                      </div>
                                    )}
                                    
                                </div>
                              { data?.convertedData?.length>0 && <div className='mt-2'>
                              <Pagination  totalRecords={totalRecords} currentPage={currentPage} onPageChange={onPageChange} firstPage={firstPage} lastPage={lastPage} setCurrentPage={setCurrentPage} />

                              </div>}
                              </div>
                              <div
                                className={`d-flex justify-content-between align-align-items-center justify-content-center gap-2 mb-2 mt-4 ${
                                  width < 1078 ? '' : 'w-100'
                                } ${width < 990 ? 'flex-column' : 'flex-row'}`}
                              >
                                <div className="d-flex gap-2">
                                  <Button
                                    variant="secondary"
                                    className={`downloading_img  ${
                                      width < 1078 ? '' : ''
                                    } btn-light`}
                                    onClick={handleGoogleShow}
                                    id="datastep"
                                  >
                                    {sheetDetails === null
                                      ? 'Connect Google Sheet'
                                      : 'Update Google Sheet connection'}
                                  </Button>
                                  <p
                                    variant="secondary"
                                    className="hyper-text m-0 align-self-center"
                                    onClick={handleShow}
                                  >
                                    Reload Sample or Template
                                  </p>
                                </div>
                                <div className={`button-div`}>
                                  <div className="with-info">
                                    <FormControlLabel
                                      className="d-flex align-align-items-center justify-content-center checkbox-custom"
                                      control={
                                        <Checkbox
                                          id="showSampleData"
                                          checked={showSampleData}
                                          onChange={(e) => {
                                            setShowSampleData(e.target.checked);
                                          }}
                                        />
                                      }
                                      label="Show sample data"
                                    />
                                    <div className="pop-info">
                                      <p>
                                        Sample data is not the real data. It's
                                        the sample of perfect data you want to
                                        collect so our AI will recognise same
                                        data later
                                      </p>
                                    </div>
                                  </div>
                                  {showSampleData && (
                                    <Button
                                      variant="secondary downloading_img"
                                      onClick={() => openAddRowModal(true)}
                                    >
                                      Add Row to Sample Data
                                    </Button>
                                  )}
                                  {JSON.parse(localStorage.getItem('user'))
                                    ?.isDeveloper ? (
                                    <Button
                                      variant="secondary downloading_img"
                                      onClick={() => openAddRowModal(false)}
                                    >
                                      Add Row to Transformed Data
                                    </Button>
                                  ) : (
                                    <div style={{ width: '1px' }} />
                                  )}
                                </div>
                                {data?.convertedData?.length > 0 ? (
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      className="exportBtn"
                                    >
                                      Export
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="export-dropdown-menu">
                                      <Dropdown.Item onClick={downloadExcel}>
                                        <i className="fas fa-file-excel me-2"></i>
                                        Excel (.xlsx)
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={downloadCSV}>
                                        <i className="fas fa-file-csv me-2"></i>
                                        CSV
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={downloadJSON}>
                                        <i className="fas fa-file-code me-2"></i>
                                        JSON
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  <div style={{ width: '150px' }} />
                                )}
                              </div>

                            </div>

                          </div>
                          
                        ) : null}
                      </Tab>
                      <Tab
                        eventKey="history"
                        // title="History"
                        title={
                          selectedConversion === 'Conversion' ? '' : 'History'
                        }
                        className="conversion"
                      >
                        <div className="Home_content_main">
                          <div className="home_content">
                            <div className="data_template_main">
                              <div className="mb-2 tableMainInner">
                                <div
                                  className="d-flex"
                                  style={{ width: '100%' }}
                                >
                                  <div
                                    className="tableData"
                                    style={{
                                      width: '100vw',
                                      maxHeight: '100vh',
                                      overflowY: 'auto',
                                    }}
                                  >
                                    <Suspense
                                      fallback={<div>Loading History...</div>}
                                    >
                                      <TransformationHistory
                                        conversionHistory={conversionHistory}
                                        isTransformationHistoryLoading={
                                          isTransformationHistoryLoading
                                        }
                                      />
                                    </Suspense>
                                    {/* <TransformationHistory conversionHistory={conversionHistory} /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="export"
                        // title="Exporting"
                        title={
                          selectedConversion === 'Conversion' ? '' : 'Exporting'
                        }
                        className="conversion"
                      >
                        <h4>Export - Choose Integration</h4>
                        <div className="Home_content_main">
                          <div className="home_content">
                            <div class="buttons-container">
                              <button
                                onClick={handleMappingSave}
                                id="saveButton"
                                className={`custom-button ${
                                  isMappingSaving ||
                                  isMappingDeleting ||
                                  isConnectionsLoading ||
                                  isFieldsLoading
                                    ? 'disabled'
                                    : ''
                                }`}
                                disabled={
                                  isMappingSaving ||
                                  isMappingDeleting ||
                                  isConnectionsLoading ||
                                  isFieldsLoading
                                }
                              >
                                {isMappingSaving ? 'Saving...' : 'Save'}
                              </button>
                              {(isMappingSaving || isMappingDeleting) && (
                                <div>
                                  <ThreeDots
                                    color="#4fa94d"
                                    height={13}
                                    width={100}
                                    visible={true}
                                  />
                                </div>
                              )}
                              <button
                                id="deleteButton"
                                className={`custom-button ${
                                  isMappingSaving ||
                                  isMappingDeleting ||
                                  !integrationMapping ||
                                  isConnectionsLoading ||
                                  isFieldsLoading
                                    ? 'disabled'
                                    : ''
                                }`}
                                disabled={
                                  isMappingSaving ||
                                  isMappingDeleting ||
                                  !integrationMapping ||
                                  isConnectionsLoading ||
                                  isFieldsLoading
                                }
                                onClick={handleDeleteMapping}
                              >
                                {isMappingDeleting ? 'Deleting...' : 'Delete'}
                              </button>
                            </div>
                           <div className="data_template_main">
                              <h5>Choose Integration</h5>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                options={connections}
                                isLoading={isConnectionsLoading}
                                onChange={handleConnectionChange}
                                value={selectedConnection}
                                isDisabled={integrationMapping}
                                // isSearchable={false}
                              />
                            </div>
                            <div>
                              {selectedConnection && (
                                <div className="data_template_main">
                                  <Suspense fallback={<div> Loading ... </div>}>
                                    <IntegrationSelect
                                      selectedConnection={selectedConnection}
                                      handleFieldsFetched={handleFieldsFetched}
                                      setResource1FromSelect={
                                        setResource1FromSelect
                                      }
                                      setResource2FromSelect={
                                        setResource2FromSelect
                                      }
                                      setResource3FromSelect={
                                        setResource3FromSelect
                                      }
                                      setResource4FromSelect={
                                        setResource4FromSelect
                                      }
                                      integrationMapping={integrationMapping}
                                      handleFieldsLoading={handleFieldsLoading}
                                      tableHeaders={tableHeaders}
                                    />
                                  </Suspense>
                                </div>
                              )}
                            </div>

                            <div className="data_template_main">
                              <Table>
                                <thead className="table_heading">
                                  <tr>
                                    <th>CSV Column</th>
                                    <th>Value From First Row</th>
                                    <th>
                                      {selectedConnection &&
                                        selectedConnection?.label.toUpperCase()}{' '}
                                      Column{' '}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="table_body">
                                  {tableHeaders?.map((header, index) => (
                                    <tr>
                                      <td>{header}</td>
                                      <td style={{ width: '45vw' }}>
                                        {tableData && tableData[0]
                                          ? tableData[0][index]
                                          : ''}
                                      </td>
                                      <td style={{ width: '15vw' }}>
                                        <Select
                                          className="basic-single"
                                          classNamePrefix="select"
                                          ref={fieldSelectRef}
                                          // options={fields}
                                          options={fields?.map((option) => ({
                                            ...option,
                                            isDisabled: isOptionDisabled(
                                              option,
                                              header,
                                            ),
                                          }))}
                                          isLoading={isFieldsLoading}
                                          isClearable={true}
                                          isDisabled={
                                            isFieldsLoading ||
                                            (integrationMapping &&
                                              integrationMapping?.mapping[
                                                header
                                              ])
                                          }
                                          // isDisabled={isFieldsLoading}
                                          onChange={(option) =>
                                            handleSelectMappingChange(
                                              option,
                                              header,
                                            )
                                          }
                                          //value={mappings[header] || null}
                                          value={
                                            (integrationMapping &&
                                              integrationMapping?.mapping[
                                                header
                                              ]) ||
                                            (mappings && mappings[header]) ||
                                            null
                                          }
                                          // value={integrationMapping && integrationMapping?.mapping[header]}
                                        />
                                      </td>
                                      <td>
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="custom-icon delete-icon"
                                          onClick={() => {
                                            if (
                                              isFieldsLoading ||
                                              (integrationMapping &&
                                                integrationMapping?.mapping[
                                                  header
                                                ])
                                            ) {
                                              handleDeleteField(header);
                                            }
                                          }}
                                          style={{
                                            cursor:
                                              isFieldsLoading ||
                                              (integrationMapping &&
                                                integrationMapping?.mapping[
                                                  header
                                                ])
                                                ? 'pointer'
                                                : 'not-allowed',
                                            color:
                                              isFieldsLoading ||
                                              (integrationMapping &&
                                                integrationMapping?.mapping[
                                                  header
                                                ])
                                                ? 'red'
                                                : 'hsl(0, 0%, 80%)',
                                            opacity:
                                              isFieldsLoading ||
                                              (integrationMapping &&
                                                integrationMapping?.mapping[
                                                  header
                                                ])
                                                ? 1
                                                : 0.5,
                                          }}
                                          title={
                                            isFieldsLoading ||
                                            (integrationMapping &&
                                              integrationMapping?.mapping[
                                                header
                                              ])
                                              ? 'Delete'
                                              : 'Delete action disabled'
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="export-logs"
                        title={
                          selectedConversion === 'Conversion'
                            ? ''
                            : 'Exporting Logs'
                        }
                        className="conversion"
                      >
                        <div className="Home_content_main">
                          <div className="home_content">
                            <div className="data_template_main">
                              <Suspense fallback={<div> Loading ... </div>}>
                                <IntegrationLogsTable
                                  integrationLogs={integrationLogs}
                                  isIntegrationLogsLoading={
                                    isIntegrationLogsLoading
                                  }
                                />
                              </Suspense>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              {/* reload modal  */}
              <Modal
                show={show}
                onHide={handleClose}
                centered
                style={{
                  marginLeft: width < 576 ? '12%' : '',
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="reloadTitle">
                    Reloading the sample of data
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="reload_modal_body_para">
                    Are you sure? After reload all uploaded data will be cleared
                  </p>
                  <div className="reload_btn_main">
                    <Button variant="dark" onClick={() => handleReload()}>
                      Reload
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              {/* appended modal  */}
              <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={appendedModal}
              >
                <Modal.Body>
                  <div className="appendedModalBody">
                    <p className="appendedTxt">Data successfully</p> <br />
                    <p className="appendedTxt">appended</p>
                    <Button
                      className="exportBtn okBtn mt-5"
                      onClick={() => setAppendedModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <GoogleSheetModal
                handleClose={handleGoogleShow}
                show={googleSheetShow}
                handleWriteReportSelected={handleWriteReportSelected}
                sheetDetails={sheetDetails}
                width={width}
                unlinkGoogleSheet={unlinkGoogleSheet}
              />
              <UploadModal
                setLoadingData={setLoadingData}
                handleClose={() => {
                  handleUpload();
                  setFilesSelected([]);
                }}
                show={uploadShow}
                setUploadTextShow={setUploadTextShow}
                handleChangeUploadFile={handleChangeUploadFile}
                loading={loadingData}
                list={list}
                handleUploadFile={handleUploadFile}
                textCheckBox={textCheckBox}
                setTextCheckBox={setTextCheckBox}
                {...{
                  conId,
                  data,
                  setData,
                  merge,
                  setMerge,
                  searchQuery,
                  setSearchQuery,
                  entireWebsite,
                  setEntireWebsite,
                  pagination,
                  setPagination,
                  noOfPages,
                  setNoOfPages,
                  defaultValues: grabData(data),
                  filesSelected,
                  setFilesSelected,
                }}
              />
              <UploadTextModal
                setLoadingData={setLoadingData}
                handleClose={handleUploadText}
                show={uploadTextShow}
                handleUploadFile={handleUploadFile}
                loading={loadingData}
                list={list}
                textCheckBox={textCheckBox}
                setTextCheckBox={setTextCheckBox}
                merge={merge}
                setMerge={setMerge}
              />
              <EditModal
                show={editModal}
                handleClose={handleEditModal}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                data={data}
                indexEdit={indexEdit}
              />
              <PlanLimitModal
                show={showLimitModal}
                handleClose={() => setShowLimitModal(false)}
              />
              <AddDataRowModal
                show={addRowModal}
                handleClose={() => setAddRowModal(false)}
                rowHeader={tableHeaders}
                sampleData={rowTypeSample}
                handleSave={async (dataToAdd) => {
                  setAddRowLoading(true);
                  try {
                    await api
                      .put(`/conversion/${data?.conversion}/addRow`, {
                        header: tableHeaders,
                        row: dataToAdd,
                        conversion: !rowTypeSample,
                      })
                      .then((res) => {
                        setAddRowLoading(false);
                        getConversionData().then(() => setAddRowModal(false));
                      });
                  } catch (e) {
                    setAddRowLoading(false);
                    console.log('Error', e);
                  }
                }}
                loading={addRowLoading}
              />
              <AddColumnModal
                show={addColumnModal}
                handleClose={() => setAddColumnModal(false)}
                appendColumn={appendColumn}
                columnName={selectedColumnName}
                handleSave={async ({
                  title,
                  description,
                  unique,
                  mandatory,
                  computeField,
                  search,
                  datatype,
                }) => {
                  setAddColumnLoading(true);
                  try {
                    await api
                      .patch(`/conversion/${data?.conversion}/addColumn`, {
                        title,
                        appendColumn,
                        columnName: selectedColumnName,
                        description,
                        unique,
                        mandatory,
                        computeField,
                        search,
                        datatype,
                      })
                      .then((res) => {
                        setAddColumnLoading(false);
                        getConversionData().then(() =>
                          setAddColumnModal(false),
                        );
                      });
                  } catch (e) {
                    setAddColumnLoading(false);
                    console.log('Error', e);
                  }
                }}
                loading={addColumnLoading}
              />
              <EditColumnTitleModal
                show={editColumnTitle}
                handleClose={() => setEditColumnTitle(false)}
                columnName={selectedColumnName}
                columnData={tableAttributes?.[selectedColumnIndex]}
                handleSave={async ({
                  title,
                  description,
                  unique,
                  mandatory,
                  computeField,
                  search,
                  datatype,
                }) => {
                  setEditColumnTitleLoading(true);
                  try {
                    await api
                      .patch(
                        `/conversion/${data?.conversion}/editColumnTitle`,
                        {
                          columnName: selectedColumnName,
                          updatedName: title,
                          description,
                          unique,
                          mandatory,
                          computeField,
                          search,
                          datatype,
                        },
                      )
                      .then((res) => {
                        setEditColumnTitleLoading(false);
                        getConversionData().then(() =>
                          setEditColumnTitle(false),
                        );
                      });
                  } catch (e) {
                    setEditColumnTitleLoading(false);
                    console.log('Error', e);
                  }
                }}
                loading={editColumnTitleLoading}
              />
            </>
          )} 
  </>
         }
        </div>
      </>
    </>
  );
};

export default Home;
